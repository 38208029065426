import React from 'react';
const Footer = () => (
  <footer>
    <p>
      Made with <i className="em em-heart" /> By Yazan{' '}
    </p>
  </footer>
);

export default Footer;
