import React, { Component } from 'react';
import Header from './components/Header';
// import Arrows from './components/Arrows';
// import Blog from './components/Blog';
// import Contact from './components/Contact';
// import Footer from './components/Footer';
class App extends Component {
  render() {
    return (
      <div className="App">
        <Header />
        {/* <Arrows />
        <Blog />
         <Contact /> 
        <Footer />*/}
      </div>
    );
  }
}

export default App;
